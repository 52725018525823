import React from 'react';

const IconLoader = () => (
  <div id="center">
    <svg id="sig" width="365" height="181" xmlns="http://www.w3.org/2000/svg">
      <title>Loader Logo</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M155.021 62.1235C164.783 56.6837 173.536 50.2453 181.581 42.4481C188.072 36.1567 188.345 30.7075 187.116 21.8192C186.54 17.6556 184.521 2.26108 177.301 2.4462C171.079 2.60575 171.021 15.6691 170.743 19.5633C169.566 36.0342 170.246 52.6025 169.999 69.1005C169.576 97.3524 169.531 126 174.511 153.942C174.771 155.4 174.649 157.464 176.418 156.012C180.557 152.612 180.79 146.036 180.79 141.197C180.79 127.456 175.896 115.18 168.673 103.637C160.55 90.6544 152.691 78.5009 138.741 71.2867C108.698 55.7495 72.0391 68.0298 44.1324 82.2174C36.3718 86.1629 29.5122 91.385 23.0151 97.1484C16.9712 102.51 9.12842 108.859 5.54914 116.359C1.90586 123.992 0.525006 138.152 7.10735 144.686C13.7493 151.278 23.7891 151.22 32.4807 150.36C43.5681 149.264 53.7087 144.967 63.1565 139.15C68.1926 136.05 76.5352 131.151 78.6689 124.987C87.04 100.804 53.6262 103.987 45.4347 119.777C41.4104 127.535 39.8685 144.046 53.0165 141.476C62.5458 139.614 69.014 131.864 76.7851 126.778C81.0139 124.01 87.5391 132.111 91.9486 132.964C99.7309 134.469 111.695 127.127 113.229 119.103C114.266 113.679 109.769 109.056 104.228 109.568C97.2443 110.213 93.5561 119.282 94.251 125.382C95.447 135.881 112.19 121.861 114.647 118.359C119.613 111.281 121.113 101.581 121.113 93.1017C121.113 85.126 121.237 109.159 122.88 116.963C123.408 119.472 126.723 133.033 132.23 128.313C136.84 124.361 138.185 115.891 138.276 110.126C138.282 109.749 139.028 118.212 139.788 120.522C142.804 129.681 152.607 129.071 160.045 126.592"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M134.927 85.985C133.631 88.2159 126.461 96.6947 131.113 98.8694C135.588 100.961 141.592 98.7398 144.881 95.4506C148.11 92.2224 146.447 88.4968 142.044 88.4968"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M167.161 75.5194C166.725 87.5771 164.868 100.844 166.51 112.917C166.824 115.222 167.945 117.427 170.045 118.545C174.116 120.712 178.504 120.02 182.651 118.638"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M150.416 103.986C160.782 99.3256 171.21 94.7704 181.488 89.9155C182.262 89.5496 183.815 88.1313 183.883 88.9852C184.27 93.8227 183.88 98.8087 183.906 103.66C183.941 110.029 185.883 121.353 193.116 112.312C197.633 106.666 196.572 108.323 201.721 110.544C204.503 111.744 208.791 110.758 211.164 109.009C212.888 107.739 213.729 105.506 214.652 103.66C214.655 103.655 218.433 108.305 219.303 108.916C225.931 113.574 231.951 106.939 234.141 100.916C234.611 99.6244 235.505 103.345 235.816 104.684C238.776 117.417 240 130.994 240.351 144.034C240.476 148.681 241.461 164.987 233.816 165.477C226.117 165.971 218.459 156.609 215.071 150.732C207.538 137.667 220.755 135.051 230.374 131.615"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M263.026 106.498C260.725 97.7121 255.859 85.7958 244.793 93.5668C240.956 96.2615 237.549 102.745 238.397 107.498C239.328 112.707 245.847 114.919 250.142 112.684C254.291 110.525 258.06 102.527 261.119 107.916C264.152 113.26 269.264 112.131 274.329 110.684"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M312.424 71.7518C311.124 86.8775 309.328 101.928 307.61 117.01C307.502 117.959 307.201 121.874 306.517 118.149C303.961 104.233 305.187 89.0992 306.331 75.1008C306.691 70.6957 306.793 65.6895 308.215 61.4258C308.781 59.727 309.193 60.0438 310.424 61.5188C317.724 70.2668 321.724 81.491 327.96 90.962C332.512 97.8753 345.87 111.602 355.124 104.405"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M309.912 96.4507C322.651 94.7963 335.33 92.9894 348.007 91.0085"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M292.33 95.6134C291.11 97.3442 286.902 101.832 287.097 104.498C287.166 105.442 288.682 104.638 288.981 104.381C290.252 103.292 289.08 102.695 288.144 101.893"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M7.66553 178.92C44.862 170.379 82.14 162.322 119.252 153.477C155.559 144.823 191.722 136.705 228.56 130.545C270.08 123.603 311.687 122.592 353.635 123.243C355.951 123.279 360.561 122.682 362.659 124.08"
          stroke="red"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </g>
    </svg>
    <h1 id="name">Adithya Anilkumar</h1>
  </div>
  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  //   <title>Loader Logo</title>
  //   <g>
  //     <g id="B" transform="translate(15.000000, 15.000000)">
  //       <path
  //         d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="currentColor"
  //       />
  //       <path d="M22.8047 26.0938H8.50781L5.29688 35H0.65625L13.6875 0.875H17.625L30.6797 35H26.0625L22.8047 26.0938ZM9.86719 22.3906H21.4688L15.6562 6.42969L9.86719 22.3906Z" fill="#E00019"/>

  //     </g>

  //     <path
  //       stroke="currentColor"
  //       strokeWidth="5"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //       d="M 50, 5
  //                 L 11, 27
  //                 L 11, 72
  //                 L 50, 95
  //                 L 89, 73
  //                 L 89, 28 z"
  //     />
  //   </g>
  // </svg>
);

export default IconLoader;
